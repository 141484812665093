import React from "react";
import { useState } from "react";
import { CgMenu, CgCloseO } from "react-icons/cg";
import { navlinks } from "../../data/data";

const Navbar = ({ logo }) => {
  const [showLinks, setShowlinks] = useState(false);

  const menuHandler = () => {
    setShowlinks(!showLinks);
  };

  const nav = navlinks.map((link, i) => {
    const { id, url, text } = link;
    return (
      <li className="navbar--link" key={id}>
        <a href={url}>{text}</a>
      </li>
    );
  });

  return (
    <div className="navbar flex">
      <div className="navbar--logo">
        <img src={logo} alt="logo" />
      </div>
      <span className="navbar--toggle" onClick={menuHandler}>
        {" "}
        {showLinks ? (
          <CgCloseO size={28} onClick={menuHandler} />
        ) : (
          <CgMenu size={28} onClick={menuHandler} />
        )}
      </span>
      <nav
        className={`${showLinks ? "navbar--links active" : "navbar--links"}`}
      >
        <ul className="navbar--list flex">{nav}</ul>
      </nav>
    </div>
  );
};

export default Navbar;