import React from "react";

const Card = () => {
  return (
    <div className="card">
      <img className="card--image" src="" alt="pic" />
      <div className="card--body">
        {/* <h1 className="card--title">Title</h1> */}
        <p className="card--content">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque iusto
          eum voluptate temporibus eaque quas illum, neque dignissimos
          voluptatum vero.
        </p>
        <button className="card--button">Button</button>
      </div>
    </div>
  );
};

export default Card;
