import React from "react";

const Notice = () => {
  return (
    <div className="background">
      <div className="notice">
        <div className="notice--info">
          <h1>Hello... welcome to Mpumalanga Basketball</h1>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Enim non
            nulla ipsa vitae rem, doloremque delectus quidem, necessitatibus
            debitis recusandae iure expedita cupiditate inventore aliquid neque
            ad odit deserunt nostrum et, saepe minima ex.
          </p>
        </div>
        <div className="notice--button">
          <a href="/" className="button-signIn">
            Sign In
          </a>
          <a href="/" className="button-logIn">
            Log In
          </a>
        </div>
      </div>
    </div>
  );
};

export default Notice;