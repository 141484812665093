import React from "react";
import Card from "./Card";

const Cardlist = () => {
  return (
    <div className="cards">
      <h1 className="component--h1">Catch Up</h1>
      <div className="cards--list">
        <Card />
        <Card />
        <Card />
      </div>
    </div>
  );
};

export default Cardlist;
