import Navbar from "./components/navbar/Navbar";
import Notice from "./components/notice/Notice";
// import Slider from "./components/slider/Slider";
import Catchup from "./components/catchup/Catchup";
import About from "./components/about/About";
// import Gallary from "./components/gallary/Gallary";
import Footer from "./components/footer/Footer";
import logo from "./assets/icon/mba4x.png";

/**
 * Import and components commnented will be presented on future updates.
 *
//  */

function App() {
  return (
    <div className="home">
      <Navbar logo={logo} />
      <Notice/>
      {/* <Slider /> */}
      <Catchup />
      <About />
      {/* <Gallary/> */}
      <Footer />
    </div>
  );
}

export default App;
