import React from "react";

const About = () => {
  return (
    <div>
      <h1 className="component--h1">About</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam,
        perspiciatis! Unde labore quibusdam magni qui consequatur aut, velit
        facilis veritatis vero quae fuga nobis. Delectus aspernatur inventore,
        eligendi quae dolorum vero! Quasi quisquam tempore architecto, ea
        ratione est cumque commodi reprehenderit expedita soluta eos dolores,
        aspernatur aperiam suscipit dolor dicta asperiores quia a animi.
        <br/>
        Consequatur quia hic adipisci voluptates a. Delectus iure obcaecati aut
        natus magnam, eaque corporis error assumenda suscipit consectetur quod
        modi explicabo, accusamus, doloremque veniam accusantium tenetur. At
        neque, voluptatum ipsa sunt maxime quod similique tempora cupiditate.
        Amet in veniam consequuntur ex similique, repudiandae suscipit quis
        culpa.
      </p>
    </div>
  );
};

export default About;
