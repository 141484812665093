import React from 'react'
import Cardlist from './Cardlist'

const Catchup = () => {
  return (
    <div>
      <Cardlist />
    </div>
  )
}

export default Catchup