// page links

export const navlinks = [
  {
    id: 1,
    url: "/",
    text: "home",
  },
  {
    id: 2,
    url: "/documents",
    text: "documents",
  },
  {
    id: 3,
    url: "/districts",
    text: "districts",
  },
  {
    id: 4,
    url: "/membership",
    text: "memberships",
  },
  {
    id: 5,
    url: "/constacts",
    text: "constacts",
  },
  // {
  //   id: 6,
  //   url: "/membership",
  //   text: "memberships",
  // },
  // {
  //   id: 7,
  //   url: "/constacts",
  //   text: "constacts",
  // },
];

// districts

export const districts = [
  { id: 1, district: "Albert Luthuli " },
  { id: 2, district: "Bushbuckridge " },
  { id: 3, district: "Dipaleseng " },
  { id: 4, district: "Ehlanzeni" },
  { id: 5, district: "Emakhazeni " },
  { id: 6, district: "Emalahleni " },
  { id: 7, district: "Gert Sibande" },
  { id: 8, district: "Govan Mbeki " },
  { id: 9, district: "JS Moroka (Dr) " },
  { id: 10, district: "Lekwa " },
  { id: 11, district: "Mkhondo " },
  { id: 12, district: "Msukaligwa " },
  { id: 13, district: "Nkangala" },
  { id: 15, district: "Nkomazi " },
  { id: 16, district: "Pixley Ka Seme " },
  { id: 17, district: "Steve Tshwete " },
  { id: 18, district: "Thaba Chweu " },
  { id: 19, district: "Thembisile Hani " },
  { id: 20, district: "Victor Khanye" },
];
